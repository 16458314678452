export const helper = {
    emailRegex:
        '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
    codeSMSRegex: /^[0-9]+$/,
    phoneRegex: '^07.{8}$',
    cnpRegex: '[0-9]{13,13}',
    validatorsAccrossApp: {
        minPassword: 8,
        maxPassword: 50,
        emailMaxLength: 50,
        minStringLength: 2,
        maxStringLength: 60,
        minSmsCodeLength: 1,
        maxSmsCodeLength: 1,
    },
    dialogConfig: {
        colorButtons: {
            standard: '#DD6B55',
            danger: '#b92929',
            warning: '#dd7a24',
            success: '#2ab27b',
            info: '#5bc0de',
            primary: '#024476',
            secondary: '#005ea4',
            light: '#f7f7f7',
            dark: '#343a40',
            default: '#007bff',
        },
        icons: {
            success: 'success',
            error: 'error',
            info: 'info',
            warning: 'warning',
        },
        headers: {
            success: 'Felicitări',
            error: 'Atenție', // "Eroare",
            errorAtentie: 'Atenție',
            successForm: 'Felicitări!',
        },
        generalMessages: {
            error:
                'Ne pare rău, dar am întâmpinat o problemă. Vă rugăm să reîncercați. Dacă problema persistă, vă rugăm să ne contactați prin butonul de suport Tehnic.',
            fileErrorMsg:
                'Ne pare rău, dar fișierul selectat nu a putut fi încărcat. Vă rugăm să reîncercați!',
            pleaseWait: 'Vă rugăm așteptați!',
        },
        buttonLabels: {
            close: 'Închide',
            save: 'Salvează',
            ok: 'Ok',
            confirm: 'Confirm',
            retry: 'Reîncercă',
        },
        commonTitles: {
            areYouSure: 'Sunteți sigur?',
        },
    },
};
