import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { CheckCodeComponent } from './check-code/check-code.component';
import { DepunereComponent } from './depunere.component';
import { IncarcareDocumenteComponent } from './incarcare-documente/incarcare-documente.component';
import { SearchDepunereComponent } from './search-depunere/search-depunere.component';
import { CentralizatorComponent } from './centralizator/centralizator.component';

const routes: Routes = [
    {
        path: '',
        component: DepunereComponent
    },
    {
        path: 'documente',
        component: IncarcareDocumenteComponent,
    },
    {
        path: 'search',
        component: SearchDepunereComponent
    },
    // {
    //     path: 'centralizator',
    //     component: CentralizatorComponent
    // },
    {
        path: 'confirmare-cod/:cerere_token',
        component: CheckCodeComponent
    }
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule],
})
export class DepunereRoutingModule { }
