import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DataTablesResponse } from 'src/app/models';
import { environment } from 'src/environments/environment';
import { helper } from 'src/environments/helper';

@Component({
    selector: 'app-centralizator',
    templateUrl: './centralizator.component.html',
    styleUrls: ['./centralizator.component.scss']
})
export class CentralizatorComponent {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    isLoading = false;
    helper = helper;
    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    dtLink = environment.interop.depunereCore.basePath + environment.interop.depunereCore.api.centralizator;

    constructor(
        private http: HttpClient,
    ) { }

    ngOnInit(): void {
        this.renderDT();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            serverSide: true,
            searching: false,
            pageLength: 25,
            responsive: true,

            ajax: (dataTablesParameters: any, callback: any) => {
                this.isLoading = true;

                this.http.post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe({
                        next: (res: any) => {
                            this.isLoading = false;
                            callback({
                                recordsTotal: res.data.recordsTotal,
                                recordsFiltered: res.data.recordsFiltered,
                                data: res.data.data,
                            });
                        },
                        error: (err: any) => {
                            this.isLoading = false;
                        }
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'Nr. Crt.',
                    data: 'index',
                    orderable: false,
                },
                {
                    title: 'Număr de dosar/solicitare',
                    data: 'reg_number',
                },
                {
                    title: 'Data depunerii',
                    data: 'created_at',
                    render: (data: any) => {
                        return this.formatRomanianDate(data);
                    }
                },
                {
                    title: 'Tip beneficiar',
                    data: 'name',
                    orderable: false,
                },
            ]
        };
    }

    rerenderDT(paginate = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
    }

    formatRomanianDate(dateTimeString: string): string {
        const [datePart, timePart] = dateTimeString.split(' ');
        const [year, month, day] = datePart.split('-');
        return `${day}.${month}.${year} ${timePart}`;
    }
}
