<div class="check-container">
    <div class="check-content">
        <h2 class="mb-4">Încărcare documente</h2>
           
        <div class="row justify-content-center">
            <h4 class="mb-3">Pentru a putea accesa secțiunea de încărcare documente vă rugăm să confirmați accesul cu un cod unic trimis la numărul dumneavoastră de telefon.</h4>
            <form [formGroup]="form">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Cod SMS</mat-label>
                    <input matInput formControlName="code" />
                    <mat-hint class="text-red">Introduceți codul primit prin SMS la numărul de telefon</mat-hint>
                    <mat-error *ngIf="(codeControl?.dirty || codeControl?.touched) && codeControl?.errors?.['required']">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="(codeControl?.dirty || codeControl?.touched) && codeControl?.errors?.['minlength']">
                        Codul trebuie să conțină 5 cifre
                    </mat-error>
                    <mat-error *ngIf="(codeControl?.dirty || codeControl?.touched) && codeControl?.errors?.['maxlength']">
                        Codul trebuie să conțină 5 cifre
                    </mat-error>
                </mat-form-field>
                <button type="button" class="negative-btn mt-4 w-auto" (click)="resendCode()">
                    Retrimite cod sms
                </button>
            </form>
           
            <button type="button" class="black-btn w-auto mt-5 mb-5" (click)="confirmCode()" [disabled]="!form.valid || loading" [ngClass]="{'disabled-btn': !form.valid || loading}">
                Pasul următor >
                <i class="fa fa-fw fa-spinner icn-spinner primary-text" aria-hidden="true" title="address-book" *ngIf="loading"></i>
            </button>
            
    
        </div>
    
        <div class="section-spinner position-absolute" *ngIf="loading">
            <div class="spinner position-absolute top-50 start-50 translate-middle">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
    
</div>
