<div class="footer">
    <div class="footer-content d-flex flex-column justify-content-start">
        <div class="footer-content-top d-flex flex-column flex-xl-row justify-content-between">
            <div>
                <div class="footer-content-top-header d-flex flex-xl-row flex-column mt-0 align-items-center">
                    <img src="assets/images/logo-guvern.png" [alt]="appName"/>
                    <span class="main-name">{{ appName }}</span>
                    <span class="short-name">MCID</span>
                </div> 
            </div>
            
            <!-- <div class="footer-content-top-right d-flex flex-xl-column flex-sm-row flex-column justify-content-center align-items-center align-items-xl-start gap-3 gap-xl-2 my-4 my-xl-0">
                <div (click)="navigateToNewTab('https://www.facebook.com/mcid.gov.ro')" class="links d-flex align-items-center gap-2">
                    <img src="assets/icons/facebook.svg" />
                    <span>Facebook</span>
                </div>
                <div (click)="navigateToNewTab('https://www.youtube.com/@mcid.romania')" class="links d-flex align-items-center gap-2">
                    <img src="assets/icons/youtube.svg" />
                    <span>Youtube</span>
                </div>
                <div (click)="navigateToNewTab('https://www.instagram.com/mcid.gov.ro/')" class="links d-flex align-items-center gap-2">
                    <img src="assets/icons/instagram.svg" />
                    <span>Instagram</span>
                </div>
                <div (click)="navigateToNewTab('https://x.com/researchgovro')" class="links d-flex align-items-center gap-2">
                    <img src="assets/icons/twitter.svg" />
                    <span>Twitter</span>
                </div>
                <div (click)="navigateToNewTab('https://www.linkedin.com/in/ministerul-cercetarii-inovarii-si-digitalizarii-2b1a39206/')" class="links d-flex align-items-center gap-2">
                    <img src="assets/icons/linkedin.svg" />
                    <span>Linkedin</span>
                </div>
            </div> -->
        </div>
        <div class="footer-content-bottom d-flex flex-column gap-5 justify-content-center align-items-center mt-xl-4">
            <div class="footer-content-bottom-menu d-flex flex-column flex-lg-row justify-content-between gap-lg-5 gap-4">
                <span (click)="navigateTo('')">Acasă</span>
                <span (click)="navigateTo('inregistrare')">Înregistrare</span>
                <span (click)="navigateTo('inregistrare/documente')">Depunere documente</span>
                <!-- <span (click)="navigateTo('inregistrare/centralizator')">Centralizator</span> -->
                <span (click)="navigateTo('faq')">Întrebări frecvente</span>
                <span (click)="navigateTo('contact')">Suport tehnic</span>
            </div>
            <div class="footer-content-bottom-copyright d-flex flex-column flex-xl-row gap-xl-5 gap-3">
                <p class="order-xl-1 order-4 mt-4 mt-xl-0">{{ copyrightText }}</p>
                <p class="copyright order-xl-2 order-1" (click)="openModal('termeni-si-conditii')">Termeni și Condiții</p>
                <p class="copyright order-xl-3 order-2" (click)="openModal('politica-de-confidentialitate')">Politica de Confidențialitate</p>
                <p class="copyright order-xl-4 order-3" (click)="openModal('politica-cookie')">Politică Cookie</p>
            </div>
        </div>
    </div>
</div>