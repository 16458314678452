<div class="upload-docs-container">
    <div class="upload-docs-content">
        <h2>Platforma de <span class="text-color-gov">încărcare</span> a documentelor de finanțare</h2>
    
        <h2 class="heading-style-h5 mt-5 mb-2">
            Bine ați venit pe platforma MCID de încărcare a Cererilor de Finanțare pentru Programul național pentru
            transformarea digitală a autorităților administrației publice locale!
        </h2>
        <div class="mb-2">
            Programul național pentru transformarea digitală a autorităților administrației publice locale are ca scop
            principal îmbunătățirea interacțiunii dintre cetățeni, mediul de afaceri și stat prin digitalizarea serviciilor
            publice.
        </div>
        <div><strong>Parcurgeți cu atenție informațiile de pe platforma de înregistrare &nbsp;ÎNAINTE &nbsp;de a încărca
                Cererea de Finanțare și Anexele aferente mai jos, până la data de 25.09.2024, ora 12:00!
                Dacă nu ați completat încă documentele, le puteți descărca </strong><a class="d-inline" href="{{coreModuleUrl}}/Documente.zip">aici</a>
        </div>
    
            <div class="mt-5">
                <label class=""><strong>Încărcați documentele în câmpurile de mai jos</strong></label>
            </div>
    
            <div class="request-upload">
                <div class="informations-container">
                    <form [formGroup]="formUpload">
    
                        <div class="mb-4">
                            <mat-hint style="font-size: 16px">
                                <img class="text-red" src="assets/icons/alert.svg" alt="" /> Câmpurile marcate cu (<span
                                    class="text-red">*</span>) sunt obligatorii
                            </mat-hint>
                        </div>
    
                        <div *ngFor="let doc of documents">
                            <div class="documents p-0">
                                <div class="upload-container">
                                    <div class="uploaded-files justify-content-center">
                                        <label class="d-flex">
                                            <span *ngIf="doc.isRequired" class="text-red">*&nbsp;</span>
                                            <span [innerHTML]="doc.name"></span>
                                            <a *ngIf="doc.url" [href]="doc.url" target="_blank">&nbsp;(Descarcă cererea)</a>
                                        </label>
                                        <ng-container>
                                            <div class="uploaded-files-container">
                                                <div *ngFor="let fg of commonService.getFormArray(formUpload, doc.key).controls"
                                                    class="success-upload docs-list-uploaded-container">
                                                    <p>{{ fg.get("original_name")?.value }}</p>
                                                    <button type="button" class="delete"
                                                        (click)="deleteFile(doc.key, fg.get('filename')?.value)">
                                                        <img class="delete-icon" src="assets/icons/delete.svg" />
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <mat-spinner diameter="32"
                                            *ngIf="commonService.getUploading(documents, doc.key)"></mat-spinner>
                                    </div>
                                    <div>
                                        <label for="{{ doc.key }}">
                                            <button type="button" (click)="fileInput.click()">
                                                <span>
                                                    <img src="assets/icons/upload.svg" alt="" />
                                                </span>
                                                <span class="show_hide_text">Încărcați</span>
                                            </button>
                                            <input #fileInput type="file" id="{{ doc.key }}"
                                                (change)="onFileSelected($event, doc.key)" class="upload-input"
                                                accept=".pdf" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
    
    
                        <div class="terms-declaration">
                            <mat-checkbox formControlName="falsInDeclaratii" color="primary">
                            </mat-checkbox>
                            <p>
                                Declar în deplină cunoștință și pe proprie răspundere, cunoscând
                                dispozițiile art. 326 Cod Penal referitoare la falsul în declarații
                                <span class="text-red">(*)</span>
                            </p>
                        </div>
                        <div class="terms-declaration">
                            <mat-checkbox formControlName="declaratieElectronica" color="primary">
                            </mat-checkbox>
                            <p>
                                Declar că sunt de acord cu transmiterea actelor administrative pe cale
                                electronică <span class="text-red">(*)</span>
                            </p>
                        </div>
                        <div class="terms-declaration">
                            <mat-checkbox formControlName="termeniConditii" color="primary">
                            </mat-checkbox>
                            <p>
                                Sunt de acord cu <u (click)="openModal(termsSlug)"><i>Termenii și Condițiile de
                                        Confidențialitate</i></u> <span class="text-red"> (*)</span> (conform GDPR)
                            </p>
                        </div>
                        <br />
                        <div>
                            <mat-hint style="font-size: 16px">
                                <img src="assets/icons/alert.svg" alt="" /> Câmpurile marcate cu (<span
                                    class="text-red">*</span>)
                                sunt obligatorii
                            </mat-hint>
                        </div>
                        <br />
                        <div>
                            <mat-hint style="font-size: 16px"><img src="assets/icons/alert.svg" alt="" />
                                Fișierele atașate trebuie să fie de tip PDF; de maxim 10MB fișierul.
                            </mat-hint>
                        </div>
    
    
    
                        <div class="mt-5 d-flex justify-content-center">
                            <button type="button" class="black-btn" (click)="sendDocuments()"
                                [disabled]="!formUpload.valid || loading"
                                [ngClass]="{'disabled-btn': !formUpload.valid || loading}">
                                Transmitere documente >
                            </button>
                        </div>
    
                    </form>
                </div>
            </div>
    
        <div class="section-spinner position-absolute" *ngIf="loading">
            <div class="spinner position-absolute top-50 start-50 translate-middle">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>

</div>