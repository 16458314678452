<div class="app-container d-flex flex-column min-vh-100">

    <div class="topbar d-flex justify-content-between align-items-center align-self-stretch">
        <div class="topbar-first d-flex align-items-center align-self-stretch w-auto">
            <img routerLink="/" src="assets/images/logo-guvern.png" [alt]="appName"/>
            <a class="app-name" routerLink="/">MCID.GOV.RO</a>
        </div>
        <div [ngClass]="{'d-none': sidenavOpen}" class="text-center topbar-icon" #menuButton>
            <mat-icon (click)="toggleSidenav()">menu</mat-icon>
        </div>
        <div [ngClass]="{'d-none': !sidenavOpen}" class="closeIcon topbar-icon" #closeButton>
            <mat-icon (click)="toggleSidenav()">close</mat-icon>
        </div>
    </div>
    <div class="sidenav-container" #sidenavContainer>
        <div class="sidenav" [class.open]="sidenavOpen">
            <div class="sidenav open-first">
                <span [class.active]="activeRoute === '/'" routerLink="" (click)="toggleSidenav()">Acasă</span>
                <span [class.active]="activeRoute === '/inregistrare'" routerLink="/inregistrare" (click)="toggleSidenav()">Înregistrare</span>
                <span [class.active]="activeRoute.startsWith('/inregistrare/') && activeRoute !== '/inregistrare'" routerLink="/inregistrare/documente" (click)="toggleSidenav()">Depunere documente</span>
                <!-- <span [class.active]="activeRoute === '/inregistrare/centralizator'" routerLink="/inregistrare/centralizator" (click)="toggleSidenav()">Centralizator</span> -->
                <span [class.active]="activeRoute === '/faq'" routerLink="/faq" (click)="toggleSidenav()">Întrebări frecvente</span>
                <span [class.active]="activeRoute === '/contact'" routerLink="/contact" (click)="toggleSidenav()">Suport tehnic</span>
            </div>        
        </div>
    </div>
    <div class="topbar-second">
        <div class="d-flex justify-content-center align-items-center">
            <span>
                {{ appName }}
            </span>
        </div>
    </div>
    <div class="topbar-third">
        <div class="d-flex justify-content-around align-items-center">
            <a [class.active]="activeRoute === '/'" routerLink="">Acasă</a>
            <a class="active-inregistrare" [class.active]="activeRoute === '/inregistrare'" routerLink="/inregistrare">Înregistrare</a>
            <a [class.active]="activeRoute.startsWith('/inregistrare/') && activeRoute !== '/inregistrare'" routerLink="/inregistrare/documente">Depunere documente</a>
            <!-- <a [class.active]="activeRoute === '/inregistrare/centralizator'" routerLink="/inregistrare/centralizator">Centralizator</a> -->
            <a [class.active]="activeRoute === '/faq'" routerLink="/faq">Întrebări frecvente</a>
            <a [class.active]="activeRoute === '/contact'" routerLink="/contact">Suport tehnic</a>
        </div>
    </div>
    <div class="pages-content flex-grow-1 d-flex flex-column">
        <app-accessibility-toolbar></app-accessibility-toolbar>

        <router-outlet></router-outlet>
    </div>

    <div class="footer">
        <app-footer></app-footer>
    </div>

</div>