import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadDocument } from 'src/app/models';
import { CommonService } from 'src/app/services/common.service';
import { DepunereService } from 'src/app/components/depunere/depunere.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { DocumentsToUpload } from './documents-to-upload';
import { helper } from 'src/environments/helper';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DialogComponent } from 'src/app/homepage/dialog/dialog.component';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-incarcare-documente',
    templateUrl: './incarcare-documente.component.html',
    styleUrls: ['./incarcare-documente.component.scss']
})
export class IncarcareDocumenteComponent implements OnInit {
    helper = helper;
    formUpload!: FormGroup;
    documents: UploadDocument[] = [];
    loading = false;
    cerereToken: string = '';
    termsSlug: string = 'termeni-si-conditii'

    errorTitle: string = environment.config.customNotifications.headers.error;
    errorIcon: string = environment.config.customNotifications.icons.error;
    errorType: string = environment.config.customNotifications.icons.error;
    // general success alert
    successTitle: string = environment.config.customNotifications.headers.successForm;
    successIcon: string = environment.config.customNotifications.icons.success;
    successType: string = environment.config.customNotifications.icons.success;

    public coreModuleUrl = environment.interop.depunereCore.basePath + 'documents';

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        public commonService: CommonService,
        private depunereService: DepunereService,
        private notificationService: NotificationService
    ) {
        this.documents = DocumentsToUpload;
        this.formUpload = this.commonService.mapToFormUpload(this.documents);
    }

    ngOnInit(): void {
        const currentData = this.depunereService.getCurrentInitIncarcareDocumente();

        if (!currentData || !currentData.token || !currentData.mainToken) {
            this.router.navigate(['inregistrare/search']);
        }
    }

    async onFileSelected(event: Event, key: string) {
        const selectedFiles = (event.target as HTMLInputElement).files;
        if (!selectedFiles) {
            return;
        }

        const uploadPromises = Array.from(selectedFiles).map(async (file) => {
            await this.commonService.upload(file, key, this.documents, this.formUpload);
        });

        try {
            await Promise.all(uploadPromises);
        } catch (error) {
            console.error(error)
        }
    }

    deleteFile(key: string, fileName: string) {
        const fa = this.commonService.getFormArray(this.formUpload, key);
        const indexToDelete = fa.controls.findIndex((control: any) => {
            const filenameControl = control.get('filename') as FormControl;
            return filenameControl.value === fileName;
        });
        if (indexToDelete >= 0) {
            fa.removeAt(indexToDelete);
        }
    }

    sendDocuments() {
        const formData = this.formUpload?.getRawValue();
        const stateData = this.depunereService.getCurrentInitIncarcareDocumente();

        var dataToSend = { ...formData, token: stateData?.token, mainToken: stateData?.mainToken }
        console.log(dataToSend);

        this.loading = true;
        this.depunereService.sendDocumente(dataToSend).subscribe({
            next: (res: any) => {
                let response = typeof res.status_code !== 'undefined' ? res : res.error;
                if (typeof response.status_code !== 'undefined') {
                    if (
                        response.status_code == 200 &&
                        typeof response.data !== 'undefined'
                    ) {
                        this.loading = false;
                        let message = 'Documentele au fost salvate cu succes!';
                        this.depunereService.clearState();
                        this.notificationService.warningSwal(this.successTitle, message, this.successIcon).then(() => this.router.navigate(['']));
                    }
                } else {
                    // add sentry
                    let errorMessage = environment.config.customNotifications.generalMessages.error;
                    this.notificationService.warningSwal(this.errorTitle, errorMessage, this.errorIcon);
                    this.loading = false;
                }
            },
            error: (err: any) => {
                this.loading = false;
                this.notificationService.handleHttpError(err);
            },
        });
    }

    openModal(slug: string) {

        let configData = {
            data: { slug: slug },
            width: '80%',
            height: '70%',
            minWidth: '275px',
        };

        const dialogRef = this.dialog.open(DialogComponent, configData);

        const layoutChanges = this.breakpointObserver.observe([
            '(max-width: 599px)', // xs breakpoint
            '(min-width: 600px)'  // sm breakpoint
        ]);

        const layoutChangeSubscription = layoutChanges.subscribe(result => {
            if (result.matches) {
                if (result.breakpoints['(max-width: 599px)']) {
                    // Smaller devices          
                    dialogRef.updateSize('95%', '80%')
                } else {
                    // Larger devices
                    dialogRef.updateSize('80%', '70%')
                }
            }
        });

        dialogRef.afterClosed().subscribe(() => {
            layoutChangeSubscription.unsubscribe()
        })
    }
}
