<div class="content">    

    <ng-container *ngIf="!depunereResponse">
        <!-- <app-countdown-timer (datesUpdated)="onDatesUpdated($event)"></app-countdown-timer> -->
        <mat-card class="form-content">
            <h2 class="title-text text-color-gov text-center">Platforma de Înregistrare</h2>
            <h2 class="welcome-text text-color-gov">Bun venit pe Platforma de înregistrare în Programul Național pentru Transformarea Digitală a Autorităților Publice Locale - #DigiLocal.</h2>
    
            <ng-container>
                <!-- <span class="info-text">
                    Parcurgeți cu atenție informațiile de pe această platformă ÎNAINTE de a
                    completa formularul de mai jos pentru înregistrarea în Platforma de Înregistrare, începând cu data de
                    XX.XX.2024 <br> <span class="check-text">Câmpurile marcate cu * sunt obligatorii</span>
                </span> -->
                <div class="header-info d-flex flex-column gap-3">
                    <span class="check-text"><strong>Important</strong>: Verificați cu atenție corectitudinea informațiilor furnizate!</span>
                    <span class="check-text">După completarea formularului, veți primi pe adresa de e-mail furnizată un e-mail de confirmare. Acest e-mail va conține un link pentru încărcarea documentelor și un număr unic de înregistrare care confirmă poziția dumneavoastră în ordinea de evaluare a proiectelor.</span>
                    <span class="check-text"><strong>ATENȚIE</strong>: Verificați și folderul SPAM sau JUNK din e-mail pentru a vă asigura că ați primit confirmarea.</span>
                    <span class="check-text">În plus, veți primi un mesaj text (SMS) pe numărul de telefon furnizat, care va conține același număr unic de înregistrare. Păstrați acest număr pentru încărcarea documentelor!</span>
                </div>
                <mat-divider></mat-divider>
                <form class="settings-container__left-address-form" [formGroup]="depunereForm">
    
                    <!-- tip beneficiar -->
                    <div class="row mb-3">
                        <mat-form-field>
                            <mat-label>Tip beneficiar</mat-label>
                            <mat-select formControlName="tip_beneficiar" (selectionChange)="tipBeneficiarChanged()">
                                <mat-option [value]="-1">-- Alege tipul beneficiarului --</mat-option>
                                <mat-option *ngFor="let beneficiarType of beneficiarTypes" [value]="beneficiarType.id">
                                    {{ beneficiarType.name }}
                                </mat-option>
                            </mat-select>
                            <mat-hint class="d-flex justify-content-between">
                                <i class="text-red">Trebuie selectată cel puțin o opțiune</i>
                            </mat-hint>
                            <mat-error *ngIf="depunereForm.controls['tip_beneficiar']?.hasError('notDefault')">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
                    </div>
    
                    <div class="row mb-3" *ngIf="showJudet()">
                        <mat-form-field>
                            <mat-label>Județ</mat-label>
                            <mat-select formControlName="judet_id" (selectionChange)="loadUatsForJudet()">
                                <mat-option [value]="-1">-- Alege județul --</mat-option>
                                <mat-option *ngFor="let judet of judete" [value]="judet.id">
                                    {{ judet.name }}
                                </mat-option>
                            </mat-select>
                            <mat-hint class="d-flex justify-content-between">
                                <i class="text-red">Trebuie selectată cel puțin o opțiune</i>
                            </mat-hint>
                            <mat-error *ngIf="depunereForm.controls['judet']?.hasError('notDefault')">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
                    </div>
    
                    <div class="row mb-3" *ngIf="showUat()">
                        <mat-form-field>
                            <mat-label>{{beneficiarConfig.uatLabel}}</mat-label>
                            <input type="text" placeholder="Căutare UAT" aria-label="Căutare UAT" matInput [formControl]="uatControl" [matAutocomplete]="auto">
                        
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let uat of filteredUats | async" [value]="uat.name + ' - ' + uat.siruta">
                                {{ uat.name }} - {{ uat.siruta }}
                                </mat-option>
                            </mat-autocomplete>
                        
                            <mat-hint class="d-flex justify-content-between">
                                <i class="text-red">Trebuie selectată cel puțin o opțiune</i>
                            </mat-hint>
                            <mat-error *ngIf="depunereForm.controls['uat_id']?.hasError('notDefault')">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <ng-container *ngIf="showFields()">
                        <!-- nume reprezentant -->
                        <div class="row mb-3">
                            <mat-form-field appearance="fill">
                                <mat-label>Numele complet al reprezentantului legal</mat-label>
                                <input formControlName="nume_reprezentant" matInput type="text" required="required" />
                                <mat-hint class="d-flex justify-content-between">
                                    <i class="text-red">Numele și Prenumele</i>
                                </mat-hint>
                                <mat-error
                                    *ngIf="legalName && (legalName.dirty || legalName.touched) && legalName.hasError('required')">
                                    Câmp obligatoriu
                                </mat-error>
                                <mat-error
                                    *ngIf="legalName && (legalName.dirty || legalName.touched) && legalName.hasError('minlength')">
                                    Minim 2 caractere
                                </mat-error>
                                <mat-error
                                    *ngIf="legalName && (legalName.dirty || legalName.touched) && legalName.hasError('maxlength')">
                                    Max 50 caractere
                                </mat-error>
                            </mat-form-field>
                        </div>
                        
                        <!-- email -->
                        <div class="row mb-3">
                            <mat-form-field appearance="fill">
                                <mat-label>Email instituțional</mat-label>
                                <input formControlName="email" matInput type="email" required="required" placeholder="email@institutie.ro" />
                                <mat-hint class="d-flex justify-content-between">
                                    <i class="text-red">IMPORTANT - emailul trebuie să fie un email instituțional; link-ul către platforma de
                                        încărcare a documentelor va fi transmis către acest email</i>
                                </mat-hint>
        
                                <mat-error *ngIf="email && (email.dirty || email.touched) && email.hasError('required')">
                                    Câmp obligatoriu
                                </mat-error>
                                <mat-error *ngIf="email && (email.dirty || email.touched) && email.hasError('pattern')">
                                    Formatul este invalid
                                </mat-error>
                            </mat-form-field>
                        </div>
        
        
                        <!-- telefon contact-->
                        <div class="row mb-3">
                            <mat-form-field>
                                <mat-label>Telefon</mat-label>
                                <input type="text" matInput formControlName="telefon" placeholder="0740999999"/>
                                <mat-hint class="d-flex justify-content-between">
                                    <i class="text-red">Verificați ca numărul de telefon să fie corect introdus deoarece va fi transmis un SMS cu un cod unic în privința depunerii de documente ulterior înregistrării solicitării</i>
                                </mat-hint>  
                                <mat-error *ngIf="telefon && (telefon.dirty || telefon.touched) && telefon.hasError('required')">
                                    Telefonul este obligatoriu.
                                </mat-error>
                                <mat-error *ngIf="telefon && (telefon.dirty || telefon.touched) && telefon.hasError('pattern')">
                                    Formatul telefonului este invalid. Trebuie să fie un număr de telefon mobil românesc valid.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                            
                    <!-- buttons -->
                    <div class="d-flex justify-content-center inregistrare-button">
                        <button type="button" class="black-btn" (click)="sendDepunere()" [disabled]="!depunereForm.valid || loading" [ngClass]="{'disabled-btn': !depunereForm.valid || loading}">
                            Înregistrare solicitare
                        </button>
                    </div>
                </form>
            </ng-container>
        </mat-card>

    </ng-container>

    <ng-container *ngIf="depunereResponse">
        <mat-card class="form-content">
            <div class="row gap-4 text-center">
                <div class="success d-flex justify-content-center align-items-center gap-3">
                    <img src="assets/icons/success-check.svg" alt="Succes" />
                    <span class="success-text">Felicitări!</span>
                </div>
                <span class="message-text">Sunteți nr. <span class="message-text-color">#{{depunereResponse.reg_number}}</span></span>
                <ul class="verify-text text-start">
                    <li><strong>Salvați numărul de înregistrare de mai sus</strong>, deoarece îl veți folosi pentru a încărca documentele.</li>
                    <li>Un <strong>link unic pentru încărcarea documentelor</strong> a fost trimis către adresa de e-mail menționată în câmpurile de înscriere.</li>
                    <li>După ce apăsați butonul <strong>"ÎNCĂRCARE DOCUMENTE" din mailul primit</strong>, veți primi un SMS care conține un cod de 5 cifre necesar pentru depunerea documentelor.</li>
                </ul>
                <div>
                    <button type="button" class="black-btn" (click)="navigateToLoadFiles()">
                        ÎNCĂRCARE DOCUMENTE
                    </button>
                </div>
            </div>
        </mat-card>
    </ng-container>

    <div class="section-spinner position-absolute" *ngIf="loading">
        <div class="spinner position-absolute top-50 start-50 translate-middle">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>