import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, finalize, map, Observable, of, startWith, Subject, switchMap } from 'rxjs';
import { BeneficiariDepunere, DepunereResponse, IdName, InitIncarcareDocumente, UatResponse } from 'src/app/models';
import { CommonService } from 'src/app/services/common.service';
import { DepunereService } from 'src/app/components/depunere/depunere.service';
import { NotificationService } from 'src/app/services/notification.service';
import { notDefaultValidator } from 'src/app/shared/validators/not-default';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { helper } from 'src/environments/helper';
import { ToastService } from 'src/app/services/toast.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { countriesList, ICountryOption, OptionRom } from 'src/app/shared/countries-list';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
    selector: 'app-depunere',
    templateUrl: './depunere.component.html',
    styleUrls: ['./depunere.component.scss']
})
export class DepunereComponent implements OnInit {
    loading = false;
    helper = helper;
    showDepunereForm = false;
    depunereForm: FormGroup;
    uatControl = new FormControl();

    judete: IdName[] = [];
    uats: UatResponse[] = [];
    filteredUats: Observable<UatResponse[]> = of([]);
    private uatsLoaded = new Subject<void>();

    beneficiarTypes: { id: number, name: string }[];
    beneficiarConfig!: any;

    depunereResponse: null | DepunereResponse = null;

    optionRom: ICountryOption = OptionRom;
    currentOption = this.optionRom;
    options: ICountryOption[] = countriesList;

    changeCountry(option: ICountryOption) {
        this.currentOption = option;
    }

    errorTitle: string = environment.config.customNotifications.headers.error;
    errorIcon: string = environment.config.customNotifications.icons.error;
    errorType: string = environment.config.customNotifications.icons.error;
    // general success alert
    successTitle: string = environment.config.customNotifications.headers.successForm;
    successIcon: string = environment.config.customNotifications.icons.success;
    successType: string = environment.config.customNotifications.icons.success;

    startDepunereDate!: Date;
    endDepunereDate!: Date;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private commonService: CommonService,
        private depunereService: DepunereService,
        private toastService: ToastService,
        private notificationService: NotificationService) {

        this.depunereForm = fb.group({
            nume_reprezentant: ['', [Validators.required]],
            email: ['', Validators.compose([Validators.pattern(environment.config.emailRegex), Validators.email])],
            telefon: ['', Validators.compose([Validators.pattern(environment.config.phoneRegex), Validators.required])],
            tip_beneficiar: fb.control(-1, [notDefaultValidator('-1')]),


            judet_id: [-1, [notDefaultValidator('-1')]],
            uat_id: [-1, [notDefaultValidator('-1')]],
            type_id: [1, []]
        });

        this.beneficiarTypes = BeneficiariDepunere.map((b) => ({ id: b.id, name: b.name }));
    }

    ngOnInit(): void {
        this.loadJudete();

        this.filteredUats = this.uatsLoaded.pipe(
            switchMap(() =>
                this.uatControl.valueChanges.pipe(
                    startWith(''),
                    //debounceTime(500),
                    map(value => this._filterUats(value || ''))
                )
            )
        );

        this.uatControl.valueChanges.subscribe(value => {
            const selectedUat = this.uats.find(uat => `${uat.name} - ${uat.siruta}` === value);
            this.uat?.setValue(selectedUat ? selectedUat.id : -1);
        });
    }

    private _filterUats(value: string): UatResponse[] {
        const filterValue = value.toLowerCase();
        return this.uats.filter(uat => (`${uat.name.toLowerCase()} - ${uat.siruta}`).includes(filterValue));
    }

    get uat() {
        return this.depunereForm.get('uat_id');
    }

    get legalName() {
        return this.depunereForm.get('nume_reprezentant');
    }

    get email() {
        return this.depunereForm.get('email');
    }

    get telefon() {
        return this.depunereForm.get('telefon');
    }

    get judet() {
        return this.depunereForm.get('judet_id');
    }

    get tipBeneficiar() {
        return this.depunereForm.get('tip_beneficiar');
    }

    hasValue(controlName: string): boolean {
        const control = this.depunereForm.get(controlName);
        return control?.value !== -1;
    }

    tipBeneficiarChanged() {
        this.beneficiarConfig = this.getBeneficiarConfig();

        this.judet?.clearValidators();
        this.uat?.clearValidators();
        this.judet?.patchValue(-1);
        this.uat?.patchValue(-1);
        this.uatControl.patchValue('');

        if (this.beneficiarConfig) {
            if (this.beneficiarConfig.showJudet) {
                this.loadJudete();
                this.judet?.setValidators([notDefaultValidator('-1')]);
            }

            if (this.beneficiarConfig.showUat) {
                this.uat?.setValidators([notDefaultValidator('-1')]);

                if (!this.beneficiarConfig.showJudet) this.loadUatsForBeneficiar(this.beneficiarConfig.id);
            }
        }

        this.judet?.updateValueAndValidity();
        this.uat?.updateValueAndValidity();
    }

    showJudet() {
        const beneficiarConfig = this.getBeneficiarConfig();

        if (!beneficiarConfig) return false;

        return beneficiarConfig.showJudet;
    }

    showUat() {
        const beneficiarConfig = this.getBeneficiarConfig();

        if (!beneficiarConfig) return false;

        if (this.showJudet() && this.judet?.value === -1) return false;

        return beneficiarConfig.showUat;
    }

    showFields() {
        const beneficiarConfig = this.getBeneficiarConfig();

        if (!beneficiarConfig) return false;

        if (this.showJudet() && this.judet?.value === -1 || this.showUat() && this.uat?.value === -1) return false;

        return true;
    }

    getBeneficiarConfig() {
        if (!this.hasValue('tip_beneficiar')) return null;

        return BeneficiariDepunere.filter(b => b.id === this.tipBeneficiar?.value)[0];
    }

    loadJudete() {
        if (this.judete && this.judete.length > 0) return;

        if (!this.beneficiarConfig) return;

        this.loading = true;

        this.commonService
            .getJudete()
            .pipe(
                map((results: any) => [...results]))
            .subscribe({
                next: (results) => {
                    this.loading = false;
                    this.judete = results;
                },
                error: (error) => {
                    this.loading = false;
                    console.error('Error loading judete', error);
                }
            })
    }

    loadUatsForJudet() {
        const selectedJudet = this.judet?.value;
        if (!selectedJudet) return;

        if (!this.beneficiarConfig.showUat) return;

        this.loading = true;

        this.commonService
            .getUats(`?beneficiar_id=${this.beneficiarConfig.id}&judet_id=${selectedJudet}`)
            .pipe(map((results: any) => [...results]))
            .subscribe({
                next: (results) => {
                    this.loading = false;
                    this.uats = results;
                    this.uat?.patchValue(-1);
                    this.uatControl.patchValue('');
                    this.uatsLoaded.next();
                },
                error: (error) => {
                    this.loading = false;
                    console.error('Error loading uats', error);
                }
            })
    }

    loadUatsForBeneficiar(beneficiarId: number) {

        this.loading = true;

        this.commonService
            .getUats(`?beneficiar_id=${beneficiarId}`)
            .pipe(map((results: any) => [...results]))
            .subscribe({
                next: (results) => {
                    this.loading = false;
                    this.uats = results;
                    this.uat?.patchValue(-1);
                    this.uatsLoaded.next();
                },
                error: (error) => {
                    this.loading = false;
                    console.error('Error loading uats', error);
                }
            })
    }

    sendDepunere() {
        this.depunereForm.markAllAsTouched();
        this.depunereForm.updateValueAndValidity();

        if (!this.depunereForm.valid) {
            this.notificationService
                .warningSwal(
                    environment.config.customNotifications.headers.error,
                    'Cererea dumneavoastră nu poate fi trimisă deoarece există erori. Verificați din nou câmpurile obligatorii și validitatea informațiilor.',
                    environment.config.customNotifications.icons.error
                )
                .then((result) => {
                    if (result.isConfirmed) {
                        setTimeout(() => {
                            console.log('scrolling to top ...');
                            window.scrollTo({ top: 0, behavior: 'auto' });
                        }, 1000);
                    }
                });

            return;
        }

        this.loading = true;

        const dataToSend = { ...this.depunereForm?.getRawValue() };

        return this.depunereService.sendDepunere(dataToSend)
            .subscribe({
                next: (res: any) => {
                    let response = typeof res.status_code !== 'undefined' ? res : res.error;
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.loading = false;
                            this.depunereResponse = { reg_number: response.data.reg_number, date: moment(response.data.date).format('DD-MM-YYYY'), token: response.data.token };

                            this.depunereService.setInitIncarcareDocumente({ mainToken: '', ...this.depunereResponse });

                            this.toastService.openToast({ title: this.successTitle, message: 'Depunerea a fost facută cu succes.', type: this.successType });
                        }
                    } else {
                        // add sentry
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        this.notificationService.warningSwal(this.errorTitle, errorMessage, this.errorIcon);
                        this.loading = false;
                    }
                },
                error: (err: any) => {
                    this.loading = false;
                    this.notificationService.handleHttpError(err.error);
                },
            });
    }

    navigateToLoadFiles() {
        this.resendCode();
    }

    resendCode() {
        this.loading = true;

        this.depunereService.resendCode({ token: this.depunereResponse?.token })
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe({
                next: (res: any) => {
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error);
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.toastService.openToast({ title: this.successTitle, message: 'Codul a fost trimis prin sms', type: this.successType })

                            this.router.navigate(['inregistrare', 'confirmare-cod', this.depunereResponse?.token]);
                        }
                    } else {
                        // general error
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        this.loading = false;
                        this.toastService.openToast({ title: this.errorTitle, message: errorMessage, type: this.errorType });
                    }
                },
                error: (err: any) => {
                    this.notificationService.handleHttpError(err.error);
                    this.loading = false;
                }
            });
    }

    // onDatesUpdated(dates: { start: Date, end: Date }) {
    //     this.startDepunereDate = dates.start;
    //     this.endDepunereDate = dates.end;

    //     const currentDate = new Date();
    //     if (currentDate >= this.startDepunereDate && currentDate <= this.endDepunereDate) {
    //         this.showDepunereForm = true;
    //     } else {
    //         this.showDepunereForm = false;
    //     }
    // }
}
