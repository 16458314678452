<div class="acasa-container">
    <div class="acasa-content">    
        
        <div class="col-md">
            <h1 class="heading-style-h2 text-color-gov text-center"><strong>Programul Național pentru Transformarea Digitală a Autorităților Publice Locale - #DigiLocal</strong></h1>
            <p>Bun venit pe pagina Programului național pentru transformarea digitală 
                a autorităților publice locale #DigiLocal, primul program integrat de 
                digitalizare a administrațiilor publice locale, instituțiile cu care românii 
                interacționează cel mai des.</p>

            <h2 class="heading-style-h4 text-color-gov mt-5">Ce beneficii are programul #DigiLocal?</h2>  
            <div class="w-richtext">
                <p>Programul #DigiLocal, gestionat de Ministerul Cercetării, Inovării și Digitalizării, are 4 beneficii majore pentru administrațiile locale și pentru cetățeni:</p>
                <ol role="list">
                    <li>interconectarea sistemelor informatice ale primăriilor și consiliilor județene cu Ghișeul.ro, platforma oficială de plăți online a statului român, pentru achiziționarea simplă, rapidă și sigură a serviciilor publice digitale;</li>
                    <li>dezvoltarea de interfețe standardizate pentru comunicarea cu cetățenii, site-uri care să fie accesibile prin câteva clickuri, astfel încât românii să utilizeze ușor și sigur serviciile publice electronice;</li>
                    <li>consolidarea securității cibernetice la nivelul administrației locale, pentru că serviciile digitale locale sunt cele mai accesate de cetățeni;</li>
                    <li>achiziția echipamentelor IT pentru creșterea capacității administrative și asigurarea interoperabilității, astfel încât românii să nu mai facă drumuri inutile și să nu mai stea la cozi.</li>
                </ol>
                <p class="mt-4"><strong>Împreună, ducem administrația locală din România la următorul nivel!</strong></p>
            </div>          
            
            <h2 class="heading-style-h4 text-color-gov mt-5">Cum puteți accesa fondurile?</h2>          
            <div class="w-richtext">
                <p>Înscrierea completă în program conține 5 pași:</p>
                <ol role="list">
                    <li>Accesați platforma de înregistrare <a class="d-inline" href="https://digilocal.mcid.gov.ro">https://digilocal.mcid.gov.ro</a> (<strong class="red-text">disponibilă din 19.09.2024 ora 11:00</strong>)</li>
                    <li><strong>Completați datele necesare înregistrării</strong> în câmpurile prestabilite:</li>
                    <ul type="list">
                        <li><strong>Denumire UAT</strong>;</li>
                        <li><strong>Numele reprezentantului legal al instituției</strong>;</li>
                        <li><strong>Adresa de e-mail a instituției: se va completa exclusiv adresa de e-mail oficială a UAT-ului</strong>;<br><strong>IMPORTANT!</strong> - linkul către platforma de încărcare a documentelor va fi transmis către această adresă de e-mail;</li>
                        <li><strong>Telefon de contact</strong> al persoanei care gestionează procesul de înregistrare, angajat al UAT-ului;</li>
                    </ul>
                    <li>Primiți pe adresa de e-mail completată mai sus un <strong>e-mail de confirmare</strong> cu linkul pentru încărcarea documentelor, care conține un număr unic de înregistrare ce vă confirmă poziția în ordinea de evaluare a proiectelor
                    <br><strong>ATENȚIE!</strong>: verificați inclusiv în secțiunile SPAM sau JUNK că ați primit mesajul. Simultan, primiți și un mesaj text (SMS) cu numărul unic de înregistrare la numărul de telefon furnizat;</li>
                    <li>Încărcați documentele necesare la linkul din e-mailul primit
                    <br><strong>ATENȚIE!</strong>: încărcați toate documentele solicitate</li>
                    <li>Echipa MCID analizează îndeplinirea criteriilor de eligibilitate prevăzute de legislația în vigoare;</li>
                </ol>
                <p>Odată cu finalizarea analizei de eligibilitate, MCID acordă finanțarea pentru proiect. <strong>Acordarea finanțării se va realiza pe principiul „primul venit, primul servit”, în ordinea cronologică a numărului de înregistrare</strong>.</p>
                <p>Fiecare UAT din România poate obține finanțare <strong>o singură dată</strong> prin Programul #DigiLocal.</p>
                <p><strong>IMPORTANT!: Finanțarea nu acoperă cheltuielile pentru o eventuală consultanță!</strong></p>
            </div>

            <h2 class="heading-style-h4 text-color-gov mt-5">Când puteți să vă înregistrați?</h2>
            <div class="w-richtext">
                <p>Platforma de înregistrare va fi activă începând de <strong class="red-text">joi, 19 septembrie, la ora 11:00</strong>.</p>
                <p>Finanțarea se realizează pe principiul „primul venit, primul servit”, în ordinea cronologică a numărului de înregistrare.</p>
                <p>Asigurați-vă că obțineți un număr de înregistrare cât mai devreme.</p>
                <p>Depunerea documentelor necesare se face până <strong class="red-text">miercuri, 25 septembrie, ora 12:00</strong>. Descărcați documentele necesare (Anexele 1-8) în secțiunea <strong>Criterii de Eligibilitate</strong>, mai jos.</p>
                <p>Finanțarea se realizează pe principiul „primul venit, primul servit”. Asigurați-vă că obțineți un număr de înregistrare cât mai devreme.</p>
            </div>
            
            <h2 class="heading-style-h4 text-color-gov mt-5">Care sunt finanțările #DigiLocal pentru primării și consilii județene?</h2>
            <div class="w-richtext">
                <p>Finanțarea se va acorda diferențiat, în funcție de tipul Beneficiarului, respectiv:</p>
                <ul role="list">
                    <li>finanțare în cuantum de maxim 75.000 lei pentru primăriile de comune</li>
                    <li>finanțare în cuantum de maxim 125.000 lei pentru primăriile de orașe</li>
                    <li>finanțare în cuantum de maxim 175.000 lei pentru primăriile de municipiu și primăriile de sector din București</li>
                    <li>finanțare în cuantum de maxim 250.000 lei pentru consiliile județene și primăria Capitalei</li>
                </ul>   
                <p>În situația în care valoarea produselor și serviciilor din cererea de finanțare depășește limitele de mai sus, diferența va fi suportată de către Beneficiar, din fonduri proprii iar, în cuprinsul cererii de finanțare vor fi detaliate cheltuielile eligibile care se vor acoperi din finanțarea prin Program.</p>
                <p><strong>ATENȚIE</strong>: Cheltuielile pentru echipamentele hardware nu trebuie să depășească 30% din valoarea totală a finanțării</p>
            </div>

            <h2 class="heading-style-h4 text-color-gov mt-5">Descrierea și obiectivele Programului</h2>
            <div class="w-richtext">
                <p><strong>Programul național pentru transformarea digitală a autorităților administrației publice locale va îmbunătăți interacțiunea dintre cetățeni, mediul de afaceri și sectorul public prin digitalizarea serviciilor publice</strong>. De asemenea, programul va sprijini direct autoritățile locale pentru creșterea eficienței și transparenței administrative.</p>
                <p>Se adresează exclusiv autorităților executive la nivelul unităților administrativ-teritoriale (UAT) și se derulează conform  și  aferente.</p>
                <p>Programul național pentru transformarea digitală a autorităților publice locale <strong>se adresează EXCLUSIV autorităților executive la nivelul unităților administrativ - teritoriale (UAT)</strong> și se derulează în temeiul <a class="d-inline" target="_blank" href="https://www.mcid.gov.ro/wp-content/uploads/2024/09/OUG-56_2024.pdf">Ordonanței de Urgență a Guvernului nr. 56/2024</a> și a <a class="d-inline" target="_blank" href="https://www.mcid.gov.ro/wp-content/uploads/2024/09/Norme-metodologice-.pdf">Normelor</a> aferente</p>
                <p>Livrabile ale programului:</p>
                <ol role="list">
                    <li><strong>dezvoltarea unor module informatice noi</strong> care să îmbunătățească activitatea autorităților locale din România prin demararea unui proces de digitalizare accelerată;</li>
                    <li><strong>echipamentele IT necesare</strong> unui sistem informatic (echipamente IT, echipamente pentru comunicații, și echipamente conexe, inclusiv licențe);</li>
                    <li><strong>migrarea către noi sisteme și infrastructuri digitale</strong>;</li>
                    <li><strong>dezvoltarea de noi aplicații informatice</strong>;</li>
                </ol>
                <p>Puteți afla mai multe detalii în secțiunea <strong>Definiții</strong>, mai jos.</p>
            </div>

            <h2 class="heading-style-h4 text-color-gov mt-5">Criteriile de eligibilitate</h2>
            <div class="w-richtext">
                <p>Evaluarea cererilor de finanțare se face conform criterilor de eligibilitate menționate în Normele metodologice pentru punerea în aplicare a Programului național pentru transformarea digitală a autorităților publice locale.</p>
                <p><strong>Descărcați documentele necesare mai jos</strong>:</p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa1.docx">Anexa 1</a> - Declarație privind capacitatea financiară a solicitantului</p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa2.docx">Anexa 2</a> - Cerere de Finanțare</p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa3.docx">Anexa 3</a> - Declarație cu privire la instruirea profesională a personalului care va utiliza echipamentele/sistemele informatice ce urmează a fi achiziționate</p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa4.docx">Anexa 4</a> - Declarație cu privire la existența sau nu a unei pagini de internet - securizată, proprie, funcțională</p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa5.docx">Anexa 5</a> - Declarație cu privire la asigurarea sustenabilității activităților/ produselor/serviciilor ce fac obiectul cererii de finanțare</p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa6.docx">Anexa 6</a> - Declarație privind îndeplinirea condiției prevăzute la art. 1 alin. (10) din OUG nr. 56/2024</p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa7.docx">Anexa 7</a> - Declarație privind destinația echipamentelor/sistemelor informatice ce urmează a fi achiziționate</p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa8.docx">Anexa 8</a> - Declarație privind înrolarea autorității executive în platforma Ghișeul.ro precum și în aplicațiile ulterioare precum ROeID și PDURo</p>
                <p><strong>Pentru cererile de finanțare declarate eligibile se încheie un contract de finanțare (<a class="d-inline" href="{{coreModuleUrl}}/anexa9.docx">Anexa 9</a>) între două părți</strong>: Ministerul Cercetării, Inovării și Digitalizării în calitate de finanțator și Autoritatea executivă la nivelul Unităților Administrativ Teritoriale, în calitate de beneficiar..</p>
                <p><strong>IMPORTANT!</strong> Nu se acorda avans, finanțarea fiind acordată o singură dată, ulterior depunerii tuturor documentelor justificative, în etapa finală, după finalizarea Proiectului.</p>
            </div>

            <h2 class="heading-style-h4 text-color-gov mt-5">Definiții utile</h2>
            <div class="w-richtext">
                <p>
                    <strong>Sisteme Informatice</strong>- prin sistem informatic se înțelege orice dispozitiv sau ansamblu de dispozitive interconectate sau aflate în relație funcțională, dintre care unul sau mai multe asigură prelucrarea automată a datelor, cu ajutorul unui program informatic, destinat furnizării de servicii publice.
                </p>
                <p>
                    <strong>Echipamente Hardware</strong>- ansamblul dispozitivelor cu ajutorul cărora datele se pot culege, verifica, prelucra, transforma, transmite, afișa și stoca datele care fac obiectul sistemului achiziționat, destinate furnizării de servicii publice.
                </p>
                <p>
                    <strong>Aplicații</strong>- Soluții software/licențe implementate/achiziționate de tip COTS - Produse disponibile care nu necesită dezvoltare ci doar instalare și/sau personalizare fiind adaptate la nevoile organizației care le achiziționează în vederea prestării de servicii publice.
                </p>
                <p>
                    <strong>Servicii Conexe</strong>- servicii suport pentru funcționarea aplicațiilor achiziționate în vederea furnizării de servicii publice.
                </p>
                <p>
                    <strong>Servicii de integrare cu aplicații/sisteme ale autorităților și instituțiilor publice centrale și europene</strong>- servicii de dezvoltare software pentru interconectarea aplicațiilor, existente la nivelul autorității executive, cu alte sisteme ale autorităților și instituțiilor publice centrale și europene în vederea furnizării de servicii publice.
                </p>
                <p>
                    <strong>Digitalizare</strong>- adoptarea continuă și implementarea tehnologiilor informației și comunicațiilor digitale pentru a transforma modul de interacțiune dintre utilizatorii și beneficiarii serviciilor publice, precum și asigurarea cadrului de interoperabilitate a sistemelor informatice.
                </p>
                <p>
                    <strong>Transformare Digitală</strong>- se referă la transformarea, folosind tehnologiile digitale și de comunicații, a:
                </p>
                <ul role="list">
                    <li>modului de interacțiune dintre administrația publică și cetățeni;</li>
                    <li>relațiilor funcționale dintre instituțiile statului;</li>
                    <li>modului în care datele generate și colectate de instituțiile statului sunt utilizate în luarea deciziilor și crearea politicilor publice;</li>
                    <li>modului în care inovarea, în cazul serviciilor publice, consideră identificarea problemei, testarea și actualizarea ca făcând parte integrantă din proces;</li>
                    <li>evaluării și anticipării continue a nevoilor publicului și adaptarea serviciilor la acestea.</li>
                </ul>
                <p>
                    <strong>Beneficiar</strong>: autoritățile executive la nivelul unităților administrativ - teritoriale, astfel cum sunt definite la art. 5 lit. n) din Ordonanța de urgență a Guvernului nr. 57/2019 privind Codul administrativ, cu modificările și completările ulterioare.
                </p>
                <p>Regăsiți <a class="d-inline" href="https://www.mcid.gov.ro/wp-content/uploads/2024/09/DigiLocal_model_site.pdf" target="_blank">aici</a> modelul de identitate vizuală recomandat pentru realizarea site-urilor UAT-urilor.</p>
            </div>
        </div>
    </div>    
</div>