import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DepunereService } from '../depunere/depunere.service';

@Component({
    selector: 'app-countdown-timer',
    templateUrl: './countdown-timer.component.html',
    styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit {
    public days: number = 0;
    public hours: number = 0;
    public minutes: number = 0;
    public seconds: number = 0;

    startDepunereDate!: Date;
    endDepunereDate!: Date;
    currentDate: Date = new Date();
    targetDate!: Date;
    countdownText: string = '';

    @Output() datesUpdated = new EventEmitter<{ start: Date, end: Date }>();

    constructor(private depunereService: DepunereService) { }

    ngOnInit(): void {
        this.fetchStartDate();
    }

    private startTimer(): void {
        setInterval(() => {
            this.currentDate = new Date();
            if (this.targetDate && this.currentDate <= this.targetDate) {
                this.calculateTimeLeft();
            }
        }, 1000);
    }

    private calculateTimeLeft(): void {
        const timeDifference = this.targetDate.getTime() - this.currentDate.getTime();

        this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        this.minutes = Math.floor((timeDifference / 1000 / 60) % 60);
        this.seconds = Math.floor((timeDifference / 1000) % 60);
    }

    private updateCountdown(): void {
        if (this.currentDate < this.startDepunereDate) {
            this.targetDate = this.startDepunereDate;
            this.countdownText = `Depunerile vor începe pe ${this.targetDate.toLocaleDateString('ro-RO')} la ora ${this.targetDate.toLocaleTimeString('ro-RO')}, mai sunt:`;
        } else if (this.currentDate >= this.startDepunereDate && this.currentDate <= this.endDepunereDate) {
            this.targetDate = this.endDepunereDate;
            this.countdownText = 'Perioada de depunere se încheie în:';
        } else {
            this.countdownText = 'Perioada de depunere s-a încheiat.';
            return;
        }

        this.calculateTimeLeft();
    }

    // daca vrem sa aducem start/end dates din server
    private fetchStartDate() {
        this.depunereService.getSubmissionTime()
            .subscribe((response: any) => {
                this.startDepunereDate = new Date(response.startDepunereDate);
                this.endDepunereDate = new Date(response.endDepunereDate);

                this.datesUpdated.emit({ start: this.startDepunereDate, end: this.endDepunereDate });

                this.updateCountdown();
                this.startTimer();
            });
    }
}
