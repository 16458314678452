import { Component } from '@angular/core';
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
    constructor() { }

    navigateToNewTab(route: string) {
        window.open(route, '_blank');
    }
}
