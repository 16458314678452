import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { AcasaComponent } from './homepage/acasa/acasa.component';
import { FaqComponent } from './homepage/faq/faq.component';
import { ContactComponent } from './homepage/contact/contact.component';

const routes: Routes = [
    {
        path: '',
        component: HomepageComponent,
        children: [
            {
                path: '',
                component: AcasaComponent,
            },
            {
                path: 'inregistrare',
                loadChildren: () => import('./components/depunere/depunere.module'
                ).then((m) => m.DepunereModule)
            },
            {
                path: 'faq',
                component: FaqComponent,
            },
            {
                path: 'contact',
                component: ContactComponent,
            }
        ],
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
